import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const API_URL = 'https://a13.team/api';
const WS_URL = 'wss://a13.team/ws';

function App() {
  const [signals, setSignals] = useState([]);
  const [symbol, setSymbol] = useState('');
  const [loading, setLoading] = useState(false);
  const [marketData, setMarketData] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const websocket = useRef(null);

  useEffect(() => {
    fetchSignals();
    connectWebSocket();

    return () => {
      if (websocket.current) {
        websocket.current.close();
      }
    };
  }, []);

  const connectWebSocket = () => {
    websocket.current = new WebSocket(WS_URL);

    websocket.current.onopen = () => {
      console.log('WebSocket connection established');
    };

    websocket.current.onmessage = (event) => {
      const notification = JSON.parse(event.data);
      setNotifications((prev) => [...prev, notification]);
    };

    websocket.current.onclose = () => {
      console.log('WebSocket connection closed');
      // Attempt to reconnect after a delay
      setTimeout(connectWebSocket, 5000);
    };
  };

  const fetchSignals = async () => {
    try {
      const response = await axios.get(`${API_URL}/signals`);
      setSignals(response.data);
    } catch (error) {
      console.error('Error fetching signals:', error);
    }
  };

  const generateSignal = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post(`${API_URL}/signals`, { symbol });
      await fetchSignals();
      setSymbol('');
    } catch (error) {
      console.error('Error generating signal:', error);
    }
    setLoading(false);
  };

  const fetchMarketData = async (symbol) => {
    try {
      const response = await axios.get(`${API_URL}/market_data/${symbol}`);
      setMarketData(response.data);
    } catch (error) {
      console.error('Error fetching market data:', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Crypto Trading Signals</h1>
      <form onSubmit={generateSignal} className="mb-4">
        <input
          type="text"
          value={symbol}
          onChange={(e) => setSymbol(e.target.value)}
          placeholder="Enter crypto symbol (e.g., BTCUSDT)"
          className="border p-2 mr-2"
        />
        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded"
          disabled={loading}
        >
          {loading ? 'Generating...' : 'Generate Signal'}
        </button>
      </form>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h2 className="text-2xl font-bold mb-2">Signals</h2>
          <div className="overflow-x-auto">
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-200">
                  <th className="px-4 py-2">Symbol</th>
                  <th className="px-4 py-2">Action</th>
                  <th className="px-4 py-2">Price</th>
                  <th className="px-4 py-2">Timestamp</th>
                  <th className="px-4 py-2">Reasoning</th>
                </tr>
              </thead>
              <tbody>
                {signals.map((signal) => (
                  <tr key={signal.id} className="border-b">
                    <td className="px-4 py-2">{signal.symbol}</td>
                    <td className="px-4 py-2">{signal.action}</td>
                    <td className="px-4 py-2">{signal.price}</td>
                    <td className="px-4 py-2">{new Date(signal.timestamp).toLocaleString()}</td>
                    <td className="px-4 py-2">{signal.reasoning}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-2">Market Data</h2>
          <button
            onClick={() => fetchMarketData(symbol)}
            className="bg-green-500 text-white p-2 rounded mb-2"
          >
            Fetch Market Data
          </button>
          <LineChart width={600} height={300} data={marketData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="timestamp" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="close" stroke="#8884d8" />
          </LineChart>
        </div>
      </div>
      <div className="mt-4">
        <h2 className="text-2xl font-bold mb-2">Notifications</h2>
        <ul className="bg-gray-100 p-4 rounded">
          {notifications.map((notification, index) => (
            <li key={index} className="mb-2">
              {notification.message}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default App;
